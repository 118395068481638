import Photo from '../assets/photo.png'
import Logo from '../assets/logo.png'

const Config = {
  title: 'Muhammad Iqbal C. Rochman',
  description: '',
  url: '',
  image: Photo,
  logo: Logo,
  twitter: '@kyuucr',
}

export default Config