import React from 'react'
import Helmet from 'react-helmet'
import config from '../config'

const Favicon = () => (
  <Helmet>
    <link rel="icon" type="image/png" href={config.logo} />
  </Helmet>
);

export default Favicon
