import React from 'react'
import Link from 'gatsby-link'

const Header = () => (
  <div className="secondary-color">
      <div style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.05rem 1.0875rem',
        // textAlign: 'center',
      }} >
          <Link
            to="/notices"
            className="link-alt"
          >
            Notices
          </Link>
      </div>
  </div>
)

export default Header
